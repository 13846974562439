import React from "react";
import { Link } from "gatsby";
import { Link as ScrollLink } from "react-scroll";
import { Container, Row, Col } from "react-bootstrap";
import logoLight from "../assets/images/logo.png";
import blogPost1 from "../assets/images/resources/exam2022.jpg";
import blogPost2 from "../assets/images/resources/sport2022.jpg";

const Footer = () => {
  return (
    <section className="site-footer">
      <div className="main-footer pt-142 pb-80">
        <Container>
          <Row>
            <Col lg={3} md={6} sm={12}>
              <div className="footer-widget mb-40 footer-widget__about">
                <Link to="/">
                  <img
                    src={logoLight}
                    className="footer-widget__logo"
                    width="101"
                    alt=""
                  />
                </Link>
                <p>
                  For a better future.
                </p>
                <ul className="list-unstyled footer-widget__contact">
                  <li>
                    <a href="#none">
                      <i className="azino-icon-telephone"></i>01722-526324
                    </a>
                  </li>
                  <li>
                    <a href="#none">
                      <i className="azino-icon-email"></i>help@shopnofoundation.org
                    </a>
                  </li>
                  <li>
                    <a href="#none">
                      <i className="azino-icon-pin"></i>Charkultia, Kalukhali,
                      Rajbari
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
            <Col lg={3} md={6} sm={12}>
              <div className="footer-widget footer-widget__link mb-40">
                <h3 className="footer-widget__title">Explore</h3>
                <ul className="list-unstyled footer-widget__link-list">
                  <li>
                    <Link disbled={true}>Our Causes</Link>
                  </li>
                  <li>
                    <Link disbled={true}>About us</Link>
                  </li>
                  <li>
                    <Link disbled={true}>New Campaign</Link>
                  </li>
                  <li>
                    <Link disbled={true}>Upcoming Events</Link>
                  </li>
                  <li>
                    <Link disbled={true}>Site Map</Link>
                  </li>
                  <li>
                    <Link disbled={true}>Help</Link>
                  </li>
                  <li>
                    <Link disbled={true}>Contact us</Link>
                  </li>
                  <li>
                    <Link disbled={true}>Terms</Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col lg={3} md={6} sm={12}>
              <div className="footer-widget mb-40 footer-widget__blog">
                <h3 className="footer-widget__title">Blog</h3>
                <ul className="list-unstyled footer-widget__blog">
                  <li>
                    <img src={blogPost1} alt="" />
                    <p>04 May, 2022</p>
                    <h3>
                      <Link disabled={true}>
                      Yearly Test 2022
                      </Link>
                    </h3>
                  </li>
                  <li>
                    <img src={blogPost2} alt="" />
                    <p>06 May, 2022</p>
                    <h3>
                      <Link disabled={true}>Yearly Sports Event 2022</Link>
                    </h3>
                  </li>
                </ul>
              </div>
            </Col>
            <Col lg={3} md={6} sm={12}>
              <div className="footer-widget mb-40 footer-widget__newsletter">
                <h3 className="footer-widget__title">Newletter</h3>
                <p>Signup now to get daily latest news & updates from us</p>
                <form
                  // data-url="https://xyz.us18.list-manage.com/subscribe/post?u=20e91746ef818cd941998c598&id=cc0ee8140e"
                  className="footer-widget__newsletter-form mc-form"
                >
                  <label htmlFor="mc-email" className="sr-only">
                    Email Address
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="mc-email"
                    className=""
                    placeholder="Email address"
                  />
                  <div className="footer-widget__newsletter-btn-wrap d-flex justify-content-end">
                    <button type="submit" className="thm-btn ">
                      Subscribe Now
                    </button>
                  </div>
                </form>
                <div className="mc-form__response"></div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="footer-bottom">
        <div className="container">
          <ScrollLink
            to="wrapper"
            smooth={true}
            duration={500}
            className="scroll-to-top"
          >
            <i className="far fa-angle-up"></i>
          </ScrollLink>
          <p>© Copyright 2022 by Shopno Foundation</p>
          <div className="footer-social">
            <a href="#none" aria-label="twitter">
              <i className="fab fa-twitter"></i>
            </a>
            <a href="https://facebook.com/shopnofoundation.org" aria-label="facebook" target="_blank">
              <i className="fab fa-facebook-square"></i>
            </a>
            <a href="#none" aria-label="pinterest">
              <i className="fab fa-pinterest-p"></i>
            </a>
            <a href="#none" aria-label="instagram">
              <i className="fab fa-instagram"></i>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
